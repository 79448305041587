import './App.css';
import PageHeader from "./components/PageHeader/PageHeader";
import Footer from "./components/Footer/Footer";
import MainPage from "./components/MainPage/MainPage";

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <PageHeader/>
      </header>
        <MainPage/>
        <Footer/>
    </div>
  );
}

export default App;
