import './PageHeader.css';
function PageHeader() {
    const handleContactClick = () => {
        window.location.href = 'mailto:me@patrycja.io';
    };
    return (
        <nav className="navbar">
            <div className="logo">Patrycja.io</div>
            <button className="contact-button" onClick={handleContactClick}>Contact</button>
        </nav>
    );
}

export default PageHeader;