import React from 'react';
import './MainPage.css';
import CubeLoader from "../CubeLoader/CubeLoader";
const MainPage = () => {
  return (
      <div className="main-page">
        <CubeLoader/>

      </div>
  );
};

export default MainPage;