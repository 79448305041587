import React from 'react';
import './CubeLoader.css';

const CubeLoader = () => {
    return (
        <div className="loader-container">
        <div className="cube">
            <div className="cube__face" id="cube__face--front">
                {[...Array(9)].map((_, index) => (
                    <span key={index} className="faceBox"></span>
                ))}
            </div>
            <div className="cube__face" id="cube__face--back">
                {[...Array(9)].map((_, index) => (
                    <span key={index} className="faceBox"></span>
                ))}
            </div>
            <div className="cube__face" id="cube__face--right">
                {[...Array(9)].map((_, index) => (
                    <span key={index} className="faceBox"></span>
                ))}
            </div>
            <div className="cube__face" id="cube__face--left">
                {[...Array(9)].map((_, index) => (
                    <span key={index} className="faceBox"></span>
                ))}
            </div>
            <div className="cube__face" id="cube__face--top">
                {[...Array(9)].map((_, index) => (
                    <span key={index} className="faceBox"></span>
                ))}
            </div>
            <div className="cube__face" id="cube__face--bottom">
                {[...Array(9)].map((_, index) => (
                    <span key={index} className="faceBox"></span>
                ))}
            </div>
        </div>
    <p className="construction-text">Page Under Construction</p>
</div>
    );
};

export default CubeLoader;
