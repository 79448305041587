import React from 'react';
import './Footer.css'; // Assuming you have a CSS file named "Footer.css" to style the footer

const Footer = () => {
  return (
      <footer className="footer">
        <p>© 2023 Patrycja Tyra. All rights reserved.</p>
      </footer>
  );
};

export default Footer;
